<!--
 * @Description: 工单评价列表页面
 * @Author: 小广
 * @Date: 2019-06-12 17:09:25
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-11 17:42:48
 -->
<template>
  <div class="OrderEvaluateList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <!-- <v-input label="社区id" placeholder="请输入社区id" v-model="searchParams.communityId"></v-input> -->
        <v-input label="工单流水号" placeholder="请输入工单流水号" v-model="searchParams.serialNumber"></v-input>

        <!-- 后端说列表都查出来了，那不是都已经评价了么 -->
        <!-- <v-input label="评价状态" placeholder="请输入评价状态" v-model="searchParams.evaluationStatus"></v-input> -->

        <v-select label="评价来源" v-model="searchParams.source" :options="evaluationSourceOps"></v-select>
        <v-datepicker label="评价时间段" format="YYYY-MM-DD HH:mm:ss"
          type="rangedatetimer" :startTime.sync="searchParams.appriseStartTime" :endTime.sync="searchParams.appriseEndTime"
        ></v-datepicker>
       
        <!-- <v-select label="评价类型" v-model="searchParams.sourceType" :options="sourceTypeOps"></v-select>
        <v-input label="服务质量" v-model="searchParams.quality"></v-input>
        <v-input label="服务速度" v-model="searchParams.speed"></v-input>
        <v-select label="是否二次修改" v-model="searchParams.appraiseAgain" :options="appraiseAgainOps"></v-select>
        <v-input label="用户姓名" placeholder="请输入完整姓名" v-model="searchParams.userName"></v-input>
        <v-select2 label="所属服务" v-model="searchParams.subId" v-bind="storeSubjectParams"></v-select2>
        <v-select2 label="商家名称" v-model="searchParams.shopId" v-bind="shopParams"></v-select2>
        <v-datepicker label="回复时间段" type="rangedatetimer" :startTime.sync="searchParams.replyStartTime" :endTime.sync="searchParams.replyEndTime"></v-datepicker> -->
      </template>
      <template #operateSlot="scope">
        <v-button :text="'查看'" type="text" permission="replyApprise" @click="look(scope.row)"></v-button>
        <!-- <v-button :text="scope.row.isReply === 0 ? '回复' : '查看'" type="text" permission="replyApprise" @click="look(scope.row)"></v-button> -->
      </template>
    </list>
  </div>
</template>

<script>
import { getSearchEvaluationList, exportListURL, getShopListURL, getStoreSubjectURL } from './api'
import { evaluationSourceOps, replyStatusMap, sourceTypeOps, appraiseAgainOps, evaluationSourceObj } from './map'
import moment from 'moment'
import { createAlinkVNode } from 'common/vdom'

export default {
  name: 'OrderEvaluateList',
  data () {
    return {
      searchUrl: getSearchEvaluationList,
      exportUrl: exportListURL,
      evaluationSourceOps,
      sourceTypeOps: sourceTypeOps(1),
      appraiseAgainOps: appraiseAgainOps(1),
      shopParams: {
        searchUrl: getShopListURL,
        request: {
          text: 'busName',
          value: 'id'
        },
        method: 'post'
      },
      storeSubjectParams: {
        searchUrl: getStoreSubjectURL,
        request: {
          text: 'subName',
          value: 'subId'
        },
        method: 'post'
      },
      searchParams: {
        // communityId: null,
        serialNumber: null,
        source: undefined,
        appriseStartTime: moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm:ss'),
        appriseEndTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
      headers: [
        {
          prop: 'serialNumberNode',
          label: '工单流水号',
          minWidth: '150px',
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.serialNumber,
              cb: this.look
            })
          }
        },
        {
          prop: 'description',
          label: '评价内容'
        },
        {
          prop: 'evaluationTime',
          label: '评价时间'
        },
        {
          prop: 'score',
          label: '评价分值'
        },
        {
          prop: 'source',
          label: '评价来源',
          formatter: (row, prop) => {
            return evaluationSourceObj[row.source]
          }
        },
        {
          prop: 'userName',
          label: '用户姓名'
        },
        // {
        //   prop: 'quality',
        //   label: '服务质量',
        //   sort: '1'
        // },
      ],
    }
  },

  methods: {
    look (row) {
      this.$router.push({
        name: 'orderSchedulingEvaluateForm',
        query: {
          id: row.targetId,
        }
      })
    }
  }
}
</script>
